import PropTypes from 'prop-types';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import i18n from '../../i18n';
import { cmsLanguageCodes } from '../../library/constants/cms.js';
import { Page } from "../layout";
import styles from './error-404.module.scss';
import { baseUrls } from '../../library/constants/cms';
import { useHubspotChat } from '../../hooks';
import { getEnvVariable } from '../../App';  

const Error404 = ({ language = i18n.language }) => {
    const { t } = useTranslation()
    const { hasLoaded, openHandler } = useHubspotChat(getEnvVariable('VITE_HUBSPOT_APP_ID'));

    // Dynamically determine the contact URL based on userLanguage
    const contactUrl = baseUrls[i18n.language]
        ? `${baseUrls[i18n.language]}/contact`
        : `${baseUrls.en}/contact`;

    return (
        <Page className={styles.pagenotfound} userLanguage={language}>
             <div className="actions" style={{justifyContent:"flex-start", width:"100%", marginTop:"10px"}}>
                <Link className="button" to={-1}><img src="/icons/arrow.svg" alt="back" /> {t('Back')}</Link>
                <Link className="button" to={0}>{t('Reload')}</Link>
            </div>
            <h1>{t('The Quotation either invalid or no longer valid.')}</h1>
            <p>
                <Trans
                    defaults='If you think this is an error, please contact <EmailLink />'
                    components={{ EmailLink: <a href="mailto:admin@leoprinting.com">admin@leoprinting.com</a> }}
                />
            </p>
            <div className="actions">          
                <div className={styles.actions} style={{ visibility: hasLoaded ? 'visible' : 'hidden' }}>
                    <a className='button' onClick={openHandler}>
                        <img src="/icons/chat.svg" alt="Chat" style={{width:"1.5rem"}} />
                        {t('Chat with us')}
                    </a>
                </div>
                <a className="button" href={contactUrl} target="_blank" rel="noopener noreferrer">
                    {t('Contact Us')}
                </a>
            </div>
            <img className={styles.mainImage} src='/images/404-lion.svg' alt="404" />
        </Page >
    )
}
Error404.propTypes = {
    language: PropTypes.oneOf(cmsLanguageCodes)
}

export default Error404