import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getEnvVariable } from '../../App.jsx';
import { useHubspotChat } from '../../hooks';
import { baseUrls, cmsLanguageCodes, contactNumber } from '../../library/constants/cms.js';
import styles from './header.module.scss';

const Header = ({ userLanguage }) => {
    const { hasLoaded, openHandler } = useHubspotChat(getEnvVariable('VITE_HUBSPOT_APP_ID'));
    const { t } = useTranslation()

    // Determine the base URL for the given user language
    const baseUrl = baseUrls[userLanguage] || 'https://www.leoprinting.com'; // Fallback URL

    return (
        <header className={`container ${styles.headerWrapper}`}>
            <div className={styles.logo}>
                <a href={baseUrl} target="_blank" rel="noopener noreferrer">
                    <img src="/images/logo.png" alt="Leoprinting" />
                </a>
            </div>
            <div className={styles.actions} style={{ visibility: hasLoaded ? 'visible' : 'hidden' }}>
                <button className={`button--plain ${styles.chatButton}`} onClick={openHandler}>
                    <img src="/icons/chat.svg" alt="Chat" />
                    {t('Chat with us')}
                </button>
                <button className={`button--primary ${styles.phoneButton}`} href={`tel:${contactNumber[userLanguage]}`}>
                    <img src="/icons/phone.svg" alt="Phone" />
                    {contactNumber[userLanguage]}
                </button>
            </div>
        </header>
    )
}
Header.propTypes = {
    userLanguage: PropTypes.oneOf(cmsLanguageCodes)
}

export default Header