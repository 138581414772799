import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useRouteLoaderData } from 'react-router-dom';
import { Image } from '../../../components/ui';
import { generateRouteUrl } from '../../../library/constants/routes.jsx';
import { getCountryById } from '../../../library/helper/country';
import { AddOrSubractDays, formatDate, formatPrice } from '../../../library/utilities';
import styles from './additional-quotation-preview.module.scss';

const getSignatureUrl = (signature, token) => {
    let url = new URL(`${import.meta.env.VITE_BASE_API_URL}/${import.meta.env.VITE_VERSION_V1}/file/read/${token}/${signature}`)
    return url.toString()
}
const Address = ({ address }) => (
    <>
        {address.street} {address.street_number} <br />
        {(address.additional_field_1 || address.additional_field_2) && (
            <>
                {address.additional_field_1} {address.additional_field_2} <br />
            </>
        )}
        {address.zipcode} <br />
        {address.city} <br />
        {address.county ? (
            <>
                {address.county} <br /> {address.country.name}
            </>
        ) : (
            address.country.name
        )}
    </>
)
Address.propTypes = {
    address: PropTypes.object
}

const AdditionalQuotationFinal = () => {
    const { t } = useTranslation()
    const { quotationToken } = useParams()
    const { quotationDetails, item, address, netAmount } = useRouteLoaderData('AdditionalQuotation')
    const handlePrintClick = () => {
        window.print()
    }
    return (
        <div className="wrapper quotation-wrapper">
            <div className="main">
                <div id="PrintableQuotation" className="inner-wrapper">
                    <div className={`inner-wrapper--title ${styles.titleWrapper}`}>
                        <span>{t("Additional Quotation Final Preview")}</span>
                        <div className="actions--compact">
                            {/* <Link reloadDocument to={getFilePath(quotation?.files?.approved_quotation, quotationToken)} className="button"><img src="/icons/download.svg" />Download</Link> */}
                            <button className="button" onClick={handlePrintClick}><img src="/icons/print.svg" alt='print' />{t("Print Confirmation")}</button>
                            <Link to={generateRouteUrl('Dashboard', { quotationToken })} className="button"><img src="/icons/stats.svg" alt='stats' />{t("Go to Dashboard")}</Link>
                        </div>
                    </div>
                    <div className={`inner-wrapper--body ${styles.wrapper}`}>
                        <div className={styles.header}>
                            <div className={styles.logo}>
                                <img src='/images/logo.svg' alt="Leoprinting" />
                            </div>
                            <img src="/images/tagline.svg" alt="tagline" />
                            {/* <div className={styles.tagline}>
                                From idea to <span className={styles.product}> product</span>
                            </div> */}
                        </div>
                        <div className={`box--large box--light-gray`}>
                            <div className="col" style={{ gridRow: "1/4" }}>
                                <h3><b>{item.customer.company}</b></h3>
                                {address.map((address) => {
                                    return <Address key={address.address_id} address={address} />
                                })}
                            </div>
                            <div className="col">
                                <h3>{t('Quotation Reference')}</h3>
                                {item.id}
                            </div>
                            <div className="col" style={{ gridRow: "2/4" }}>
                                <h3>{t("Contact Name")}</h3>
                                {item.primary_contact.first_name} {item.primary_contact.last_name}
                            </div>
                            <div className="col">
                                <h3>{t("Quotation Date")}</h3>
                                {formatDate(item.createdAt)}
                            </div>
                            <div className="col">
                                <h3>{t("Quotation Valid Upto")}</h3>
                                {formatDate(AddOrSubractDays(new Date(item.createdAt), 30, true))}
                            </div>
                            <div className="col">
                                <h3>{t("Client Order Number")}</h3>
                                {item.client_order_number || t('N/A')}
                            </div>
                        </div>
                        <div className="box--large">
                            <p>{t("Dear")} {item.primary_contact.first_name} {item.primary_contact.last_name},</p>
                            <p>{t("It is our pleasure to make the following quotation:")}</p>
                        </div>
                        {/* {quotation.items.map(item => <QuotationItemPreview key={item.id} item={item} currency={quotation.currency} />)} */}
                    </div>
                    <div >
                        <div className={`inner-wrapper--body ${styles.wrapper}`}>
                            <div className="box--large box--light-gray">
                                {item.client_comment && <h3>{t("Client Note")}</h3>}
                                {item.client_comment}
                            </div>
                            <div className="box--light-gray" style={{ gap: 'var(--space-large)' }}>
                                <div className="box--dark-gray col">
                                    <h4>{t("File Delivery")}</h4>
                                    <p>{t("PDf or EPS file. Any questions? Please contact us")}</p>
                                    <p></p>
                                    <h4>{t("Delivery")}</h4>
                                    <p>{t("Registered delivery to ")} {getCountryById(item.customer.country_id)?.name}</p>
                                    <p></p>
                                    <h4>{t("Delivery")}</h4>
                                    <p>{t("2 to 3 weeks after approval of the proof. A shorter delivery time can be arranged")}.</p>
                                </div>
                                <div className="box--dark-gray col">
                                    <h4>{t("Prices")}</h4>
                                    <p>{t("Inclusive setup costs")}</p>
                                    <p>{t("Inclusive studio support")}</p>
                                    <p>{t("Inclusive digital proof for approval")}</p>
                                    <p>{t("Exclusive VAT")}</p>
                                </div>
                            </div>
                            <div className="box--large box--light-gray">
                                <p>{t("This quotation is valid for 30 days from the date of stated on the quotation. Please note that additional costs may apply based on Brexit Ruling. Please notify us of any mistakes. Your business is of great value to us and we hope we can be of service to you. The image(s) in this quotation are an indication only, the customised production will deviate from this.")}</p>
                                <h3>{t("Items and Services")}</h3>
                                <div className={styles.totals}>
                                    <table className={styles.totalRow}>
                                        <thead>
                                            <tr>
                                                <th>{t("Item Name")}</th>
                                                <th>{t("Desciption")}</th>
                                                <th></th>
                                                <th>{t("Sales Price/Piece")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {quotationDetails?.item_additional_quotation_lines.map((quote) => {
                                                return <tr key={quote?.id}>
                                                    <td>{quote?.name}</td>
                                                    <td>{quote?.description}</td>
                                                    <td></td>
                                                    <td>{formatPrice(quote?.sales_price, item.currency?.code)}</td>
                                                </tr>
                                            })}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th colSpan="3">{t("Net Amount")}</th>
                                                <th>{formatPrice(netAmount, item?.currency?.code)}</th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            <div className="box--large box--light-gray">
                                <h3>Signature</h3>
                                <div className={`box--large ${styles.signatureContainer}`}>
                                    <div className={styles.contact}>
                                        <div className={styles.name}>{item.signed_by.first_name} {item.signed_by.last_name}</div>
                                        <div className={styles.email}>{item.signed_by.email}</div>
                                    </div>
                                    <Image src={getSignatureUrl(quotationDetails?.pdf?.signature, quotationToken)} width="300" height="60" />
                                </div>
                                <p><strong>Contact :</strong></p>
                                <div className={styles.name}>{item.contact.first_name} {item.contact.last_name}</div>
                                <div className={styles.email}>{item.contact.email}</div>
                            </div>
                            <div className="box center">
                                <p><strong>{t("Passion for creating your excellent promotion.")} </strong></p>
                                <p>{t("80-83 Long Lane, London EC1A 9ET  -  Tel: +44 20 3608 1945  -  E-mail: info@leoprinting.com")}<br />{t("Private Limited Company Number 765685")}</p>
                                <hr style={{ width: '40%', minWidth: '250px' }} />
                                <p>{t("Leoprinting is part of Leogroup")}.</p>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div >
    )
}


export default AdditionalQuotationFinal
