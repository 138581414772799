import PropTypes from 'prop-types';
import React from "react";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from '../../../../components/project/project-item.module.scss';
import { ItemOrderStatus } from '../../../../library/constants/client-statuses.js';
import { generateRouteUrl } from '../../../../library/constants/routes.jsx';
import { DifferenceBetweenDates, subtractDaysFromDate } from '../../../../library/utilities/date.js';

const ARTWROK_BUFFER_DAYS = 18

const ArtworkInReview = ({ quotationToken, item }) => {
    const { t } = useTranslation();
    const daysLeft = item.deadline_artwork_customer ?
        DifferenceBetweenDates(Date.now(), item.deadline_artwork_customer) :
        item.client_expected_delivery_date ?
            DifferenceBetweenDates(Date.now(), subtractDaysFromDate(item.client_expected_delivery_date, ARTWROK_BUFFER_DAYS)) :
            null


    const CalendarIcon = ({ daysLeft }) => {
        return (
            <svg width="50" height="50" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="2" y="5" width="16" height="12" stroke="#23B17C" strokeWidth="0.7" rx="2" />
                <path d="M13.2456 1V4.42843M6.38873 1V4.42843" stroke="#23B17C" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M2 5.83429H17.6343" stroke="#23B17C" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />

                {/* Days Left Inside Calendar */}
                <text x="10" y="14" textAnchor="middle" fontSize="6" fill="#23B17C" fontWeight="bold">
                    {daysLeft}
                </text>
            </svg>
        );
    };

    return <div className={`box--white ${styles.projectItem}`}>
        <div className={`box--plain ${styles.tasksBox}`}>
            <h3 style={{ 'textAlign': 'start' }}>{t("Task For You")}</h3>
            {item.order_status === ItemOrderStatus.ARTWORK_REJECTED ? <div>
                <div className={styles.redBox}>
                    <ul>
                        <li className={styles.redDot}>
                            <span>
                                {!!daysLeft && (
                                    <div className={styles.daysLeftBox} style={{background:"unset"}}>
                                        <CalendarIcon daysLeft={daysLeft} />
                                        <span style={{fontSize:"20px",fontWeight:"700"}}>{t('Days Left')}</span>
                                    </div>
                                )}
                            </span>
                            <span className={styles.text}>{t('The Artwork you sent has been rejected')}</span><br />
                            {/* <span className={styles.text}>{t('We’re updating the artwork for you. You will receive an email as soon as the new digital proof is ready for your review.')}</span><br /> */}
                            <span className={styles.text}><strong>{t('Reason')}: </strong> {item.artwork_rejected_reason}</span>
                        </li>
                    </ul>
                </div>
                <br />
                <div className={`box--blue-light ${styles.task}`}>
                    <div className={styles.taskContent}>
                        <span>{t("Please Correct the artwork and upload again")}</span>
                    </div>
                    <div className={styles.taskAction}>
                        <Link to={generateRouteUrl('ClientArtwork', {
                            quotationToken,
                            itemId: item.id,
                        })} className={styles.button}>{t("Upload Artwork")}</Link>
                    </div>
                </div>
            </div> : [ItemOrderStatus.ARTWORK_SEND_TO_STUDIO, ItemOrderStatus.ARTWORK_READY, ItemOrderStatus.ARTWORK_RECEIVED, ItemOrderStatus.NEW].includes(item.order_status) ?
                <div className={`box--blue-light ${styles.task}`}>
                    <div className={styles.taskContent}>
                        {/* <span>{t("Your artwork is under review")}</span> */}
                        <span className={styles.text}>{t('We’re updating the artwork for you. You will receive an email as soon as the new digital proof is ready for your review.')}</span>
                    </div>
                </div> : item.order_status === ItemOrderStatus.REQUEST_VENDOR_FOR_PROOF ?
                    <div className={`box--blue-light ${styles.task}`}>
                        <div className={styles.taskContent}>
                            <span>{t("Your artwork is approved")}</span>
                        </div>
                    </div> : <div></div>
            }
            {item.client_confirm_address === null ? (
                <div className={`box--blue-light ${styles.task}`}>
                    <div className={styles.taskContent}>
                        <span>{t('Fill in the delivery address and confirm ?')}</span>
                    </div>
                    <div className={styles.taskAction}>
                        <Link to={generateRouteUrl('ProjectItemAddress', {
                            quotationToken,
                            itemId: item.id
                        })} className="button--primary">{t('Add Address')}</Link>
                    </div>
                </div>
            ) : (
                <div className={`box--blue-light ${styles.task}`}>
                    <div className={styles.taskContent}>
                        {t('Delivery Address is already confirmed')}
                    </div>
                    <div className={styles.taskAction}>
                        <Link to={generateRouteUrl('ProjectItemAddress', {
                            quotationToken,
                            itemId: item.id
                        })} className="button--primary">{t('View Address')}</Link>
                    </div>
                </div>
            )}
        </div>
    </div>
}
ArtworkInReview.propTypes = {
    quotationToken: PropTypes.string.isRequired,
    item: PropTypes.shape({
        id: PropTypes.number,
        product: PropTypes.shape({
            name: PropTypes.string.isRequired,
            main_photos: PropTypes.arrayOf(PropTypes.string),
        }).isRequired,
        deadline_artwork_customer: PropTypes.string,
        order_status: PropTypes.number,
        client_expected_delivery_date: PropTypes.string,
        make_artwork_comment: PropTypes.string,
        request_for_template: PropTypes.number,
        client_confirm_address: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        template_comment: PropTypes.string,
        documents: PropTypes.object,
        artwork_rejected_reason: PropTypes.string,
    })
}

export default ArtworkInReview