import PropTypes from 'prop-types';
import React from 'react';
import { Footer, Header } from '.';
import { cmsLanguageCodes } from '../../library/constants/cms.js';
import { HeaderBar } from './header-bar.jsx';

const Page = ({ children, projectId = null, userLanguage, className, ...props }) => (
    <>
        <div className="sticky-header">
            <Header userLanguage={userLanguage} />
            <HeaderBar projectId={projectId} language={userLanguage} />
        </div>
        <div className={['container page', className].join(' ')} {...props}>
            {children}
        </div>
        <Footer userLanguage={userLanguage} />
    </>
)

Page.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    userLanguage: PropTypes.oneOf(cmsLanguageCodes)
}

export default Page