import PropTypes from 'prop-types';
import React from "react";
import { ItemClientStatuses } from '../../../library/constants/client-statuses.js';
import { ArtworkInReview, Delivered, FinalApproval, InProduction, InTransit, UploadArtwork } from '../index';
import Cancelled from './cancelled';



const StepBody = ({ data, quotationToken }) => {
    switch (data.client_status) {
        case ItemClientStatuses.ORDER_CONFIRMATION: return <UploadArtwork item={data} quotationToken={quotationToken} />
        case ItemClientStatuses.UPLOAD_ARTWORK: return <ArtworkInReview item={data} quotationToken={quotationToken} />
        case ItemClientStatuses.FINAL_APPROVAL: return <FinalApproval item={data} quotationToken={quotationToken} />
        case ItemClientStatuses.IN_PRODUCTION: return <InProduction />
        case ItemClientStatuses.IN_TRANSIT: return <InTransit item={data} quotationToken={quotationToken}/>
        case ItemClientStatuses.DELIVERED: return <Delivered data={data} quotationToken={quotationToken} />
        case ItemClientStatuses.CANCELLED: return <Cancelled />
        default: return <div></div>
    }
}

StepBody.propTypes = {
    data: PropTypes.object,
    quotationToken: PropTypes.string,
}
export default StepBody