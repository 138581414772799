import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { redirect, useFetcher, useLoaderData, useParams } from 'react-router-dom';
import { changeLanguage } from '../../App.jsx';
import { otpLogin, requestOtp } from '../../api/auth/login.js';
import { getQuotationDetails } from '../../api/quotation/quotation.js';
import Footer from '../../components/layout/footer.jsx';
import { HeaderBar } from '../../components/layout/header-bar.jsx';
import Header from '../../components/layout/header.jsx';
import i18n from '../../i18n.js';
import { contactNumber, contactUrls } from '../../library/constants/cms.js';
import { generateRouteUrl } from '../../library/constants/routes.jsx';
import { LocalStorage } from '../../library/utilities/localstorage.js';
import styles from './login.module.scss';

const RE_DIGIT = new RegExp(/^\d+$/);
const RESENT_OTP_DELAY = 60 * 1000

const OtpInput = ({ value, valueLength, onChange, onSubmit }) => {
  const inputOnKeyDown = (e) => {
    const target = e.target;
    const targetValue = target.value;

    // keep the selection range position
    // if the same digit was typed
    target.setSelectionRange(0, targetValue.length);

    if (e.key !== 'Backspace' || target.value !== '') {
      return;
    }

    const previousElementSibling = target.previousElementSibling

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  }

  const inputOnFocus = (e) => {
    const { target } = e;
    target.setSelectionRange(0, target.value.length);
  };

  const inputOnChange = (e, idx) => {
    const target = e.target;
    let targetValue = target.value;
    const isTargetValueDigit = RE_DIGIT.test(targetValue);

    if (!isTargetValueDigit && targetValue !== '') {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : ' ';

    const targetValueLength = targetValue.length;

    if (targetValueLength === 1) {
      const newValue =
        value.substring(0, idx) + targetValue + value.substring(idx + 1);

      triggerChange(newValue);

      if (!isTargetValueDigit) {
        return;
      }

      const nextElementSibling = target.nextElementSibling;

      if (nextElementSibling) {
        nextElementSibling.focus();
      }
    } else if (targetValueLength === valueLength) {
      triggerChange(targetValue);

      target.blur();
    }
  }

  const triggerChange = (val) => {
    onChange(val)
    if (val.length === valueLength && RE_DIGIT.test(val)) {
      onSubmit(val)
    }
  }


  const valueItems = useMemo(() => {
    const valueArray = value.split('');
    const items = [];

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push('');
      }
    }
    return items;
  }, [value, valueLength])

  return (
    <div className={styles.otpGroup}>
      {valueItems.map((digit, idx) => (
        <input
          key={idx}
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          pattern="\d{1}"
          maxLength={valueLength}
          className={styles.otpInput}
          value={digit}
          onChange={(e) => inputOnChange(e, idx)}
          onKeyDown={inputOnKeyDown}
          onFocus={inputOnFocus}
        />
      ))}
    </div>
  );
}

OtpInput.propTypes = {
  value: PropTypes.string,
  valueLength: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
}

const getTimeLeft = (token) => parseInt((Date.now() - LocalStorage(token).get('lastOtpRequest')) / 1000)

const OtpResendButton = ({ onClick }) => {
  const { t } = useTranslation()
  const { quotationToken } = useParams()
  const [timeLeft, setTimeLeft] = useState(getTimeLeft(quotationToken))
  const timer = useRef(null)

  useEffect(() => {
    timer.current = setInterval(() => {
      setTimeLeft(getTimeLeft(quotationToken));
    }, 1000);
    return () => {
      clearInterval(timer.current)
    }
  }, [quotationToken])

  if (timeLeft < RESENT_OTP_DELAY / 1000) {
    return (
      <button className='button' disabled>{t('You can resend code after {{delay}} seconds', { delay: (RESENT_OTP_DELAY / 1000 - timeLeft) })}</button>
    )
  }
  return (
    <button className='button' onClick={onClick}>{t('Resend Code')}</button>
  )
}
OtpResendButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

const Login = () => {
  const { project_id, quotation, userLanguage, cms_language_code } = useLoaderData()
  const [otp, setOtp] = useState('');
  const fetcher = useFetcher()
  const { error = null } = fetcher.data || {}
  const { t } = useTranslation()

  const requestOtp = useCallback(() => {
    fetcher.submit({
      action: 'requestOtp'
    }, {
      method: 'post',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    requestOtp()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOtpSubmit = (val) => {
    fetcher.submit({
      otp: val,
      action: 'authenticate'
    }, {
      method: 'post',
    })
  }

  // Dynamically determine the contact URL based on userLanguage
  const contactUrl = contactUrls[userLanguage] ? contactUrls[userLanguage] : contactUrls.en

  return (
    <>
      <div className="sticky-header">
        <Header userLanguage={userLanguage} />
        <HeaderBar projectId={project_id} language={userLanguage} cmsLanguage={cms_language_code} />
      </div>
      <div className="container page">
        <div className="wrapper">
          <div className="main">
            <div className="box--white bg-lion">
              <div className={styles.loginWrapper}>
                <h2>{t("Enter Verification Number")}:</h2>
                <p>
                  {t("Input the received number in the space provided on the order page.")}
                  {quotation
                    ? t("Once verified, you'll be prompted to electronically sign the order confirmation.")
                    : t("Once verified, you'll be able to view/manage your order.")}
                </p>
                <img src="/icons/check_1_layerstyle.svg" alt="checked mail" style={{ margin: '1rem auto 2rem auto' }} />
                {!!error && (
                  <p className='error' style={{ margin: 0 }}>{error}</p>
                )}
                <OtpInput value={otp} valueLength={6} onChange={(val) => setOtp(val)} onSubmit={handleOtpSubmit} />
                <br />
                <OtpResendButton onClick={requestOtp} disabled={fetcher.state !== 'idle'} />
                <p>
                  <Trans
                    defaults="If you haven’t received a code, please <ContactLink /> us."
                    components={{ ContactLink: <a href={contactUrl} target="_blank" rel="noopener noreferrer">{t('contact')}</a> }}
                  /> <a href={`tel:${contactNumber[cms_language_code]}`}>{contactNumber[cms_language_code]}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer userLanguage={userLanguage} />
    </>
  )
}
Login.Actions = {
  requestOtp: async ({ params }) => {
    const { quotationToken } = params
    //Check if otp is sent already
    const lastOtpTime = parseInt(LocalStorage(quotationToken).get('lastOtpRequest'))
    if (lastOtpTime && (Date.now() - lastOtpTime) < RESENT_OTP_DELAY) {
      return {
        error: i18n.t('Verification code already requested.')
      }
    }
    try {
      await requestOtp(quotationToken)
    }
    catch (error) {
      LocalStorage(quotationToken).set('lastOtpRequest', null)
      return {
        error: error.message
      }
    }
    return true
  },
  authenticate: async ({ params, data, url }) => {
    const { quotationToken } = params
    const { otp } = data
    try {
      if (!otp || otp.length !== 6) {
        return {
          error: i18n.t("Invalid Verification Code")
        }
      }
      const { data } = await otpLogin({}, {
        otp,
        token: quotationToken
      })
      localStorage.setItem('USER', JSON.stringify(data));
      let params = new URL(url).searchParams;
      let from = params.get("from");
      let redirectTo = from ?? generateRouteUrl('Dashboard', {
        quotationToken
      })
      return redirect(redirectTo);
    }
    catch (error) {
      return {
        error: i18n.t(error.message)
      }
    }
  },
}

Login.Loader = async ({ params }) => {
  /**
    * todo: Add a mechanism to detect if an otp request is already
    * send to prevent sending multiple requests withien a specified 
    * period of time, may be using cookie expiration period
    */
  const { quotationToken } = params
  const { project_id, cms_language_code, quotation } = await getQuotationDetails(quotationToken)

  if (!project_id) {
    throw new Response("", { status: 404 });
  }

  let userLanguage = navigator.language.split('-')?.[0] ?? 'en'
  if (cms_language_code) {
    userLanguage = cms_language_code.split('_')?.[1] ?? cms_language_code
  }
  changeLanguage(userLanguage)
  return { project_id, quotation, userLanguage, cms_language_code }
}

export default Login