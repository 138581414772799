import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, redirect, useLoaderData, useNavigation, useParams } from 'react-router-dom';
import { getApprovedQuotationDetails } from '../../api/quotation';
import { changeLanguage } from '../../App.jsx';
import Footer from '../../components/layout/footer.jsx';
import { HeaderBar } from '../../components/layout/header-bar.jsx';
import Header from '../../components/layout/header.jsx';
import PDFViewer from '../../components/layout/PDFViewer.jsx';
import { QuotationItemPreview } from '../../components/quotation';
import { Image, Modal } from '../../components/ui';
import { Entities } from '../../library/constants';
import { baseUrls } from '../../library/constants/cms.js';
import { generateRouteUrl } from '../../library/constants/routes.jsx';
import { AddOrSubractDays, formatDate, formatPrice, getFilePath } from '../../library/utilities';
import { isEmpty } from '../../library/utilities/common.js';
import styles from './quotation-final.module.scss';

const getSignatureUrl = (signature, token) => {
    let url = new URL(`${import.meta.env.VITE_BASE_API_URL}/${import.meta.env.VITE_VERSION_V1}/file/read/${token}/${signature}`)
    return url.toString()
}
const Address = ({ address }) => (
    <>
        {address.street} {address.street_number} <br />
        {(address.additional_field_1 || address.additional_field_2) && (
            <>
                {address.additional_field_1} {address.additional_field_2} <br />
            </>
        )}
        {address.zipcode} <br />
        {address.city} <br />
        {address.county ? (
            <>
                {address.county} <br /> {address.country.name}
            </>
        ) : (
            address.country.name
        )}
    </>
)
Address.propTypes = {
    address: PropTypes.object
}

const QuotationFinal = () => {
    const { quotationToken } = useParams()
    const { quotation, userLanguage, project_id, footer } = useLoaderData()
    const [fileModal, showFileModal] = useState(false)
    const navigation = useNavigation()
    const { t } = useTranslation();

    const vatLines = quotation.items.reduce((acc, { price }) => {
        if (!acc[price.vat.vat_rate]) {
            acc[price.vat.vat_rate] = 0
        }
        acc[price.vat.vat_rate] += price.vat.vat_amount
        return acc
    }, {})

    // Dynamically determine the contact URL based on userLanguage
    const contactUrl = baseUrls[userLanguage]
        ? `${baseUrls[userLanguage]}/contact`
        : `${baseUrls.en}/contact`;

    return (
        <>
            <div className="sticky-header">
                <Header userLanguage={userLanguage} />
                <HeaderBar projectId={project_id} menu={''} language={userLanguage} />
            </div>
            <div className={`container page ${["loading", "submitting"].includes(navigation.state) ? 'loading' : ''}`}>
                <div className="wrapper quotation-wrapper">
                    <div className="main">
                        <div id="PrintableQuotation" className="inner-wrapper">
                            <div className={`inner-wrapper--title ${styles.titleWrapper}`}>
                                <span>{t("Signed Order Confirmation")}</span>
                                <div className="actions--compact">
                                    <Link reloadDocument to={getFilePath(quotation.files.approved_quotation, quotationToken)} className="button"><img src="/icons/download.svg" />{t('Download')}</Link>
                                    <button className="button" onClick={() => { showFileModal(true) }}><img src="/icons/print.svg" />{t('Print Confirmation')}</button>
                                    <Link to={generateRouteUrl('Dashboard', { quotationToken })} className="button--primary"><img src="/icons/stats.svg" />{t('Go to Dashboard')}</Link>
                                    <Modal onClose={() => showFileModal((prev) => !prev)} isVisible={fileModal}>
                                        <PDFViewer path={quotation.files.approved_quotation} type={"pdf"} />
                                    </Modal>
                                </div>
                            </div>
                            <div className={`inner-wrapper--body ${styles.wrapper}`}>
                                <div className={styles.header}>
                                    <div className={styles.logo}>
                                        <img src='/images/logo.svg' alt="Leoprinting" />
                                    </div>
                                    <img src="/images/tagline.svg" alt="tagline" />
                                </div>
                                <div className={`box--large box--light-gray`}>
                                    <div className="col" style={{ gridRow: "1/4" }}>
                                        <h3>{quotation.customer.company}</h3>
                                        <Address address={quotation.address} />
                                    </div>
                                    <div className="col">
                                        <h3>{t("Quotation Reference")}</h3>
                                        {quotation.id}
                                    </div>
                                    <div className="col" style={{ gridRow: "2/4" }}>
                                        <h3>{t("Contact Name")}</h3>
                                        {quotation.account_manager.name}
                                    </div>
                                    <div className="col">
                                        <h3>{t("Quotation Date")}</h3>
                                        {formatDate(quotation.createdAt)}
                                    </div>
                                    <div className="col">
                                        <h3>{t("Quotation Valid Upto")}</h3>
                                        {formatDate(AddOrSubractDays(new Date(quotation.createdAt), 30, true))}
                                    </div>
                                    {quotation.client_order_number && (
                                        <div className="col">
                                            <h3>{t("Client Order Number")}</h3>
                                            {quotation.client_order_number}
                                        </div>
                                    )}
                                </div>
                                <div className="box--large">
                                    <p>{t("Dear")} {quotation.primary_contact.first_name} {quotation.primary_contact.last_name},</p>
                                    <p>{t("It is our pleasure to make the following quotation:")}</p>
                                </div>
                                {quotation.items.map(item => <QuotationItemPreview key={item.id} item={item} currency={quotation.currency} />)}
                            </div>
                        </div>
                        <div className="inner-wrapper" style={{ marginTop: 'var(--space-xxlarge)' }}>
                            <div className={`inner-wrapper--body ${styles.wrapper}`}>
                                {quotation.client_comment?.length > 0 && (
                                    <div className="box--large box--light-gray">
                                        {quotation.client_comment && <h3>{t("Client Note")}</h3>}
                                        {quotation.client_comment}
                                    </div>
                                )}
                                <div className="box--light-gray" style={{ gap: 'var(--space-large)' }}>
                                    <div className="box--dark-gray col">
                                        <h4>{t("File Delivery")}</h4>
                                        <p>
                                            <Trans
                                                defaults='PDF or EPS file. Any questions? Please <ContactLink />.'
                                                components={{
                                                    ContactLink: <a href={contactUrl} target="_blank" rel="noopener noreferrer">{t('contact us')}</a>
                                                }}
                                            />
                                        </p>
                                    </div>
                                    <div className="box--dark-gray col">
                                        <h4>{t("Prices")}</h4>
                                        <p>{t("Inclusive setup costs")}</p>
                                        <p>{t("Inclusive studio support")}</p>
                                        <p>{t("Inclusive digital proof for approval")}</p>
                                        <p>{t("Exclusive VAT")}</p>
                                    </div>
                                </div>
                                <div className="box--large box--light-gray">
                                    <p>{t("This quotation is valid for 30 days from the date of stated on the quotation. Please note that additional costs may apply based on Brexit Ruling. Please notify us of any mistakes. Your business is of great value to us and we hope we can be of service to you. The image(s) in this quotation are an indication only, the customised production will deviate from this.")}</p>
                                    <h3>{t("Items and Services")}</h3>
                                    <div className={styles.totals}>
                                        <table className={styles.totalRow}>
                                            <thead>
                                                <tr>
                                                    <th><strong>{t("Item Name")}</strong></th>
                                                    <th><strong>{t("Quantity")}</strong></th>
                                                    <th><strong>{t("Sales Price/Piece")}</strong></th>
                                                    <th><strong>{t("VAT")}</strong></th>
                                                    <th><strong>{t("Total Price")}</strong></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {quotation.items.map(item => (
                                                    <tr key={item.id}>
                                                        <td>{item.product.name}</td>
                                                        <td>{item.price.quantity}</td>
                                                        <td>{formatPrice(item.price.sales_price, quotation.currency.code)}</td>
                                                        <td>{!!item.price.vat && `${item.price.vat.vat_rate}%`}</td>
                                                        <td>{formatPrice(item.price.total_price_excl_vat, quotation.currency.code)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            <tfoot>
                                                <tr style={{ borderBottom: '1px var(--color-green-line) solid' }}></tr>
                                                <tr>
                                                    <td>{t('Sub Total')}</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    {/* <td>{formatPrice(quotation.total - quotation.total_vat, quotation.currency?.code)}</td> */}
                                                    <td>{formatPrice(quotation.total, quotation.currency?.code)}</td>
                                                </tr>
                                                {Object.values(vatLines)?.length > 1 && Object.entries(vatLines).map(([vat_rate, vat_amount]) => (
                                                    <tr key={vat_rate}>
                                                        <td><i>{t('VAT Rate')} - {vat_rate}%</i></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>{formatPrice(vat_amount, quotation.currency?.code)}</td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <td>{t('Total VAT')}</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>{formatPrice(quotation.total_vat, quotation.currency?.code)}</td>
                                                </tr>
                                                <tr>
                                                    <th colSpan="4">{t("Net Amount")}</th>
                                                    <th>{formatPrice(quotation.total + (quotation.total_vat || 0), quotation.currency.code)}</th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                                {[Entities.NL, Entities.BE, Entities.DE].includes(quotation.entity_id) && (
                                    <div className="box--large box--light-gray">
                                        <h3>{t('Important Notice Regarding VAT')}:</h3>
                                        <p>{t('This order confirmation includes VAT by default. If the order qualifies for an intra-Community supply or export and you have a valid VAT number, you can provide it to us. In that case, we will issue the final invoice without VAT. Please provide us with the VAT number as soon as possible.')}</p>
                                    </div>
                                )}
                                <div className="box--large box--light-gray">
                                    <h3>{t("Signature")}</h3>
                                    <div className={`box--large ${styles.signatureContainer}`}>
                                        <div className={styles.contact}>
                                            <div className={styles.name}>{quotation.signed_by?.first_name} {quotation.signed_by?.last_name}</div>
                                            <div className={styles.email}>{quotation.signed_by?.email}</div>
                                        </div>
                                        <Image src={getSignatureUrl(quotation.signature, quotationToken)} width="300" height="60" />
                                    </div>
                                    <p><strong>{t("Contact")} :</strong></p>
                                    <div className={styles.name}>{quotation.account_manager.name}</div>
                                    <div className={styles.email}>{quotation.account_manager.email}</div>
                                </div>
                                <div className="box center" dangerouslySetInnerHTML={{ __html: footer }} />
                            </div>
                        </div>
                    </div>
                </div >
            </div>
            <Footer userLanguage={userLanguage} />
        </>
    )
}


QuotationFinal.Loader = async ({ params }) => {
    const { quotationToken, quotationId } = params
    const { quotation, project_id, meta } = await getApprovedQuotationDetails(quotationToken, quotationId)

    if (!quotation || isEmpty(quotation)) {
        return redirect(generateRouteUrl('Dashboard', { quotationToken }))
    }

    const userLanguage = quotation.contact_language ?? (navigator.language.split('-')?.[0] ?? 'en')
    changeLanguage(userLanguage)

    return {
        quotation,
        project_id,
        userLanguage,
        footer: meta?.footer
    }
}

export default QuotationFinal
