import React from 'react';
import { Link, Outlet, RouterProvider, createBrowserRouter, isRouteErrorResponse, useLoaderData, useRouteError } from 'react-router-dom';
import { getQuotationDetails } from './api/quotation/quotation.js';
import { Error404 } from './components/errors/index.js';
import Page from './components/layout/page.jsx';
import i18n from './i18n';
import { getStoredToken, getUser, isAuthenticated, redirectToLogin, routesList } from "./library/constants";
import { formDataToObject } from './library/utilities/formdata.js';
import Login from './routes/login/login.jsx';
const formatRoute = (route) => {
  const { id = undefined, component: Component, title, url: path, isProtected = true } = route
  const obj = {
    element: Component ? <Component title={title} /> : <div><Outlet /></div>,
  }
  if (id) {
    obj.id = id
  }
  if (path) {
    obj.path = path
  }
  else {
    obj.index = true
  }
  obj.handle = {
    isProtected: !!isProtected
  }
  if (Component?.ErrorElement) {
    obj.errorElement = <Component.ErrorElement />
  }
  if (route.children) {
    obj.children = route.children.map(formatRoute)
  }

  if (route?.isProtected) {
    obj.loader = (params) => {
      if (!params?.params?.quotationToken || !isAuthenticated(params.params.quotationToken)) {
        return redirectToLogin(params.params.quotationToken, new URL(params.request.url).pathname)
      }
      if (Component?.Loader) {
        return Component.Loader(params)
      }
      return {}
    }
  }
  else if (Component?.Loader) {
    obj.loader = Component.Loader
  }

  if (Component?.Action && Component?.Actions) {
    throw new Error('Route cannot have both `Action` and `Actions`', route)
  }

  if (Component?.Actions) {
    obj.action = async ({ params, request }) => {
      //const { action, ...data } = Object.fromEntries(await request.formData())
      let parsedRequestBody = null
      if (request.headers.get('content-type') === 'application/json') {
        parsedRequestBody = await request.json()
      }
      else {
        parsedRequestBody = formDataToObject(await request.formData())
      }
      const { action, ...data } = parsedRequestBody
      const { method } = request
      if (!Component.Actions[action]) {
        throw new Error(`Unknow action: ${action} on route: ${Component.name}`, route)
      }
      return await Component.Actions[action]({ params, method, data })
    }
  }
  else if (Component?.Action) {
    obj.action = Component.Action
  }
  return obj
}

// const loadTranslation = async (language) => {
//   const translations = await import(`../public/locales/${language}/common.json`);
//   i18n.addResourceBundle(language, 'translation', translations, true, true);
//   return translations;
// }

// Example of changing the language and loading translations lazily
export const changeLanguage = async (newLanguage) => {
  // await loadTranslation(newLanguage);
  // i18n.changeLanguage(newLanguage);
  // await loadTranslation(newLanguage);
  i18n.changeLanguage(newLanguage)
  const url = new URL(window.location)
  if (url.searchParams.get('language') !== newLanguage) {
    url.searchParams.set('language', newLanguage)
    window.history.pushState(null, '', url)
  }
}

const browserRouter = createBrowserRouter([
  {
    element: <Outlet />,
    id: 'Root',
    errorElement: <ErrorBoundary />,
    loader: async ({ params }) => {
      const { quotationToken } = params
      if (!quotationToken) {
        throw new Response("", { status: 404 });
      }
      const user = getUser(quotationToken)

      return { user }
    },
    children: [
      {
        errorElement: <ErrorBoundary />,
        children: [...routesList.map(formatRoute)],
      },
    ]
  },
  {
    path: '/authenticate/:quotationToken',
    element: <Login />,
    errorElement: <ErrorBoundary />,
    loader: Login.Loader,
    action: async ({ params, request }) => {
      //const { action, ...data } = Object.fromEntries(await request.formData())
      const { action, ...data } = formDataToObject(await request.formData())
      const { method, url } = request
      if (!Login.Actions[action]) {
        throw new Error(`Unknow action: ${action} on route: Login`)
      }
      return await Login.Actions[action]({ params, method, data, url })
    },
  },
  {
    path: '*',
    Component: () => {
      const { language } = useLoaderData()
      return <Error404 language={language} />
    },
    errorElement: <ErrorBoundary />,
    loader: async ({ params }) => {
      const { "*": wildcardPath = '' } = params;
      let [section, token] = wildcardPath.split("/");
      if (!token) {
        //Check if user info is already there
        token = getStoredToken()
      }
      // if (!section || !['authenticate', 'dashboard', 'quotation', 'approvedquotation', 'additional-quotation'].includes(section) || !token) {
      if (!token) {
        return {}
      }
      const user = getUser(token)
      if (user?.contact_language) {
        return { language: user?.contact_language }
      }
      let userLanguage = navigator.language.split('-')?.[0] ?? 'en'
      const { cms_language_code } = await getQuotationDetails(token)
      if (cms_language_code) {
        userLanguage = cms_language_code.split('_')?.[1] ?? cms_language_code
      }
      changeLanguage(userLanguage)
      return { language: userLanguage }
    }
  }
  // {
  //   //path: '/',
  //   element: <AuthenticatedPage />,
  //   errorElement: <ErrorBoundary />,
  //   children: routesList.map(formatRoute),
  //   action: ({ params, data }) => {
  //     return {}
  //   },
  //   loader: ({ params }) => {
  //     const { quotationToken } = params
  //     let userLanguage = navigator.language.split('-')?.[0] ?? 'en'
  //     //Check if user is logged
  //     if (localStorage.getItem('USER')) {
  //       const { token, contact_language } = JSON.parse(localStorage.getItem('USER'))
  //       if (token === quotationToken && contact_language) {
  //         userLanguage = contact_language.split('_')?.[1] ?? contact_language
  //       }
  //     }
  //     changeLanguage(userLanguage)
  //     //changeLanguage(userLanguage)
  //     return { userLanguage }
  //   }
  // }
])

function App() {
  return (
    <RouterProvider router={browserRouter} />
  );
}

function ErrorBoundary() {
  let error = useRouteError();
  console.log({ error })
  if (isRouteErrorResponse(error) || error.constructor === Response) {
    if (error.status === 404) {
      return <Error404 />;
    }
  }
  console.error("Inside global error boundary", error);

  //throw error
  //Uncaught ReferenceError: path is not defined
  return (
    <Page projectId='' className="center" userLanguage={i18n.language}>
      <img src='/images/404-lion.svg' style={{ maxWidth: '40%', maxHeight: '30vh' }} alt="404" />
      <h2>Some unexpected error has occurred</h2>
      <h3>Leoprinting has been notified about the error.</h3>
      <p>Error Details : {error.message}</p>
      <div className="actions">
        <Link className="button" to={-1}><img src="/icons/arrow.svg" alt="back" /> Back</Link>
        <Link className="button" to={0}>Reload</Link>
      </div>
    </Page>
  );
}

export const getEnvVariable = (key) => {
  if (!import.meta.env[key]) {
    console.warn(`Please configure ${key} in environment`)
    return ''
  }
  return import.meta.env[key]
}

export default App;