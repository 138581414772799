import React from 'react';
import { isRouteErrorResponse, Link, Outlet, redirect, useLoaderData, useNavigation, useRouteError, useRouteLoaderData } from 'react-router-dom';
import { getQuotationDetails } from '../../api/quotation/quotation.js';
import { changeLanguage } from '../../App.jsx';
import { Error404 } from '../../components/errors';
import Footer from '../../components/layout/footer.jsx';
import { HeaderBar } from '../../components/layout/header-bar.jsx';
import Header from '../../components/layout/header.jsx';
import Page from '../../components/layout/page.jsx';
import { QuotationStatuses } from '../../library/constants/client-statuses.js';
import { generateRouteUrl } from '../../library/constants/routes.jsx';

const ErrorElement = () => {
    let error = useRouteError();
    let { project_id, userLanguage } = useRouteLoaderData('QuotationRoot') || {};

    if (isRouteErrorResponse(error) || error.constructor === Response) {
        if (error.status === 404) {
            return <Error404 />;
        }
    }

    return (
        <Page projectId={project_id} className="center" userLanguage={userLanguage} >
            <img src='/images/404-lion.svg' style={{ maxWidth: '40%', maxHeight: '30vh' }} alt="404" />
            <h2>Some unexpected error has occurred</h2>
            <h3>Leoprinting has been notified about the error.</h3>
            <p>Error Details : {error.message}</p>
            <div className="actions">
                <Link className="button" to={-1}><img src="/icons/arrow.svg" alt="back" /> Back</Link>
                <Link className="button" to={0}>Reload</Link>
            </div>
        </Page>
    );
}

const QuotationRoot = () => {
    const navigation = useNavigation()
    const { project_id, userLanguage } = useLoaderData()

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%', minHeight: '100vh' }}>
            <div className="sticky-header">
                <Header userLanguage={userLanguage} />
                <HeaderBar projectId={project_id} language={userLanguage} />
            </div>
            <div className={`container page ${["loading", "submitting"].includes(navigation.state) ? 'loading' : ''}`}>
                <Outlet />
            </div>
            <Footer userLanguage={userLanguage} />
        </div>
    );
}

QuotationRoot.Loader = async ({ params, request }) => {
    const { quotationToken } = params
    let userLanguage = navigator.language.split('-')?.[0] ?? 'en'
    const { quotation = null, status, project_id, cms_language_code, qoutationStatus, project_rejected_reason, meta } = await getQuotationDetails(quotationToken)

    if (!project_id && !quotation) {
        throw new Response("Invalid Quotation", { status: 404 });
    }

    if (!quotation && !status) {
        throw new Response("Quotation Rejected", { status: 404 });
    }

    if (qoutationStatus === QuotationStatuses.REJECTED) {
        const currentUrl = new URL(request.url);
        const currentPathname = currentUrl.pathname;
        if (currentPathname !== generateRouteUrl('QuotationRejected', { quotationToken })) {
            return redirect(generateRouteUrl('QuotationRejected', { quotationToken }))
        }
    }

    if (!quotation && status) {
        return redirect(generateRouteUrl('Dashboard', { quotationToken }))
    }

    if (cms_language_code) {
        userLanguage = cms_language_code.split('_')?.[1] ?? cms_language_code
    }
    changeLanguage(userLanguage)

    return { quotation, userLanguage, status, project_id, project_rejected_reason, footer: meta?.footer }
}

QuotationRoot.ErrorElement = ErrorElement

export default QuotationRoot
