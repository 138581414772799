import { customAlphabet } from 'nanoid/non-secure';
import { useEffect, useMemo, useState } from 'react';
const nanoid = customAlphabet('1234567890abcdef', 6)
const LIMIT_ADDRESSES = 5
const MIN_ADDRESSES = 2

const addressFill = (addresses, addressesCount, minAddresses, maxAddresses, maxQuantity) => {
    // Step 1: Slice initial addresses to keep it within the limit
    const slicedAddresses = addresses.slice(0, Math.min(addressesCount, maxAddresses))

    // Step 2: Calculate how many placeholders are needed to meet the address count requirement
    const placeholdersNeeded = Math.max(
        0, // At minimum, we need 0 placeholders (to avoid negative numbers)
        Math.max(addressesCount, minAddresses) - slicedAddresses.length
    );

    // Step 3: Create placeholder addresses if needed
    const placeholders = Array(placeholdersNeeded).fill({
        placeholder: true,
        address_id: null,
        notes: '',
        quantity: null,
    })


    // Step 4: Combine the actual addresses and the placeholders
    let finalAddresses = slicedAddresses.concat(placeholders).slice(0, LIMIT_ADDRESSES)

    // Step 5: Ensure the total quantity does not exceed maxQuantity
    let totalQuantity = finalAddresses.reduce((sum, item) => sum + (item.quantity || 0), 0);

    if (totalQuantity > maxQuantity) {
        let remainingQuantity = maxQuantity;

        finalAddresses = finalAddresses.map(item => {
            if (!item.quantity) return item; // Skip placeholders or items without quantity
            const adjustedQuantity = Math.min(item.quantity, remainingQuantity);
            remainingQuantity -= adjustedQuantity;
            return { ...item, quantity: adjustedQuantity };
        });
    }

    // Step 6: Assign a unique id if the item does not already have one
    return finalAddresses.map(item => ({ ...item, id: item.id || nanoid() }));
}

const useMultipleAddresses = ({ initialAddresses = [], totalQuantity, addressesCount, onChange }) => {
    const [addresses, setAddresses] = useState(addressFill(initialAddresses, addressesCount, MIN_ADDRESSES, LIMIT_ADDRESSES, totalQuantity))

    const remainingQuantity = useMemo(() => {
        return addresses.reduce((remaining, address) => !isNaN(parseInt(address.quantity)) ? remaining - parseInt(address.quantity) : remaining, totalQuantity)
    }, [addresses, totalQuantity])
    // const getCurrentTotal = (items) => items.reduce((t, { quantity }) => t + Number(quantity), 0)

    const isValid = useMemo(() => {
        const quantityUsed = addresses.reduce((p, q) => (p + q.quantity), 0)
        const allAddressesSelected = !addresses.some(({ address_id }) => !address_id)
        return (quantityUsed === totalQuantity && allAddressesSelected)
    }, [addresses, totalQuantity])

    useEffect(() => {
        setAddresses(addressFill(initialAddresses, addressesCount, MIN_ADDRESSES, LIMIT_ADDRESSES, totalQuantity))
    }, [addressesCount])

    // const updateAddress = (id, address) => {
    //     if (isNaN(address.quantity)) {
    //         return
    //     }

    //     const currentIndex = addresses.findIndex(a => a.id === id)
    //     const _addresses = addresses.map(a => ({ ...a }))
    //     const extraIndex = _addresses.findIndex(a => a.id !== id && !a.address_id && !a.notes)

    //     _addresses[currentIndex] = { ...address, id }

    //     // If total exceeds, check if we can adjust
    //     if (getCurrentTotal(_addresses) > totalQuantity) {
    //         if (extraIndex < 0 || totalQuantity - getCurrentTotal(_addresses) > _addresses[extraIndex].quantity) {
    //             // Can't adjust
    //             _addresses[currentIndex].quantity += (totalQuantity - getCurrentTotal(_addresses))
    //             setAddresses(_addresses)
    //             return
    //         }

    //         // Adjustable
    //         _addresses[extraIndex].quantity -= (getCurrentTotal(_addresses) - totalQuantity)
    //     } else if (getCurrentTotal(_addresses) < totalQuantity && _addresses.length < LIMIT_ADDRESSES) {
    //         // Create extra item if not already there
    //         if (extraIndex < 0) {
    //             _addresses.push({
    //                 id: nanoid(),
    //                 address_id: 0,
    //                 notes: "",
    //                 quantity: totalQuantity - getCurrentTotal(_addresses)
    //             })
    //         } else {
    //             _addresses[extraIndex].quantity += (totalQuantity - getCurrentTotal(_addresses))
    //         }
    //     }

    //     setAddresses(_addresses.filter(a => a.quantity > 0))
    // }

    const updateAddress = (id, address) => {
        let newQuantity = parseInt(address.quantity)
        if (isNaN(newQuantity)) {
            newQuantity = null
        }
        const prevQuantity = addresses.find(a => a.id === id)?.quantity
        if (prevQuantity !== newQuantity && remainingQuantity < (newQuantity - prevQuantity)) {
            return
        }
        const _addresses = addresses.map(a =>
            a.id === id ? { ...address, id } : a
        )
        setAddresses(_addresses)
        if (typeof onChange === 'function') {
            console.log(_addresses)
            onChange(_addresses)
        }
    }

    return [addresses, updateAddress, isValid, remainingQuantity]
}

export default useMultipleAddresses