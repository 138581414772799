import { generatePath } from "react-router-dom";
import { axiosInstance } from "../common";

const MAIN_ENDPOINT = '/quotation';
const APPROVED_ENDPOINT = '/quotation/:quotationId';
const ADDITIONALQUOTATION = '/quotation/:token/:quotationId';

const MAIN_ENDPOINT_ACTION = '/quotation/:action';
const DOCUMENT_ENDPOINT = '/documents';

//export const getQuotationDetails = (token) => axiosInstance.get(generatePath(MAIN_ENDPOINT, { token }));
export const getQuotationDetails = (token) => axiosInstance.get(MAIN_ENDPOINT, {
    params: {
        token
    }
}).then(({ data, meta }) => ({ ...data, meta }))

export const getAdditionalQuotationDetails = (token, quotationId) => axiosInstance.get(generatePath(ADDITIONALQUOTATION, {
    quotationId,
    token
}), {
    params: {
        token
    }
}).then(({ data }) => data)

export const approveQuotation = (token, signatureId, quantityIds) => axiosInstance.post(MAIN_ENDPOINT, {
    signature: signatureId,
    id: quantityIds
}, {
    params: {
        token
    }
}).then(({ data }) => data)


export const deleteQuotation = (token) => axiosInstance.post(generatePath(MAIN_ENDPOINT_ACTION, { action: 'rejected' }),
    {},
    {
        params: {
            token
        }
    }
)

export const getApprovedQuotationDetails = (token, quotationId) => axiosInstance.get(generatePath(APPROVED_ENDPOINT, {
    quotationId
}), {
    params: {
        token
    }
}).then(({ data, meta }) => ({ ...data, meta }))

export const getVisuals = (token) => axiosInstance.get(DOCUMENT_ENDPOINT, {
    params: {
        token
    }
}).then(({ data }) => data.visual ?? [])