import React from "react";
import { useTranslation } from 'react-i18next';
import styles from '../../../../components/project/project-item.module.scss';
import { DifferenceBetweenDates } from "../../../../library/utilities";




const InTransit = ({ quotationToken, item }) => {
    const { t } = useTranslation();
    const time = item.client_expected_delivery_date ? DifferenceBetweenDates(Date.now(), item.client_expected_delivery_date) : null

    const CalendarIcon = ({ daysLeft }) => {
        return (
            <svg width="50" height="50" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="2" y="5" width="16" height="12" stroke="#23B17C" strokeWidth="0.7" rx="2" />
                <path d="M13.2456 1V4.42843M6.38873 1V4.42843" stroke="#23B17C" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M2 5.83429H17.6343" stroke="#23B17C" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />

                {/* Days Left Inside Calendar */}
                <text x="10" y="14" textAnchor="middle" fontSize="8" fill="#23B17C" fontWeight="bold">
                    {daysLeft}
                </text>
            </svg>
        );
    };

    return <div className={`box--white ${styles.projectItem}`}>
        <div className={`box--plain ${styles.tasksBox}`}>
            <h3 style={{ 'textAlign': 'start' }}>{t('Task For You')}</h3>
            <div className={`box--blue-light ${styles.task}`}>
                <span className={styles.transitContent}>{t("The order is shipped.  The Delivery will take")}
                    <CalendarIcon daysLeft={time} />
                    <span style={{fontSize:"20px",fontWeight:"700"}}>{t("days")}</span>{t("to reach")}
                </span>
            </div>
        </div>
    </div>
}

export default InTransit